import React, { useContext } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { LanguageContext } from "../../contextap/LanguageContext";

const AboutFour = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="section section-padding case-study-featured-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured-thumb text-start">
              <img
                src={process.env.PUBLIC_URL + "/images/others/case-study-4.png"}
                alt={language === "en-US" ? "travel" : "seyahat"}
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <span className="subtitle">
                  {language === "en-US" ? "Who we are" : "Biz Kimiz"}
                </span>
                <h2 className="title">
                  {language === "en-US"
                    ? "We are at the first stop of those who change the world."
                    : "Dünyayı değiştirenlerin ilk durağıyız."}
                </h2>
                <p>
                  {language === "en-US"
                    ? "At Faem Software, we are managed by an experienced team and approach each project with care. We specialize in various areas, including design, mobile and web application development, and online marketing. We are dedicated to providing unique and effective solutions by understanding our customers' needs."
                    : "Faem Software olarak deneyimli bir ekibin yönetimindeyiz ve her projeye özenle yaklaşıyoruz. Tasarım, mobil ve web uygulama geliştirme ve online pazarlama dahil olmak üzere çeşitli alanlarda uzmanlaşıyoruz. Müşterilerimizin ihtiyaçlarını anlayarak benzersiz ve etkili çözümler sunmaya adanmışız."}
                </p>
                <p>
                  {language === "en-US"
                    ? "Experienced and Creative Team"
                    : "Deneyimli ve Yaratıcı Bir Ekip"}
                </p>
                <Link to="#" className="axil-btn btn-fill-primary btn-large">
                  {language === "en-US" ? "Read More" : "Daha Fazlasını Oku"}
                </Link>
              </div>
              <div className="case-study-counterup">
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? <CountUp end={4} duration={1} /> : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">
                    {language === "en-US"
                      ? "Years on the market"
                      : "Pazardaki Yıllar"}
                  </span>
                </div>
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? <CountUp end={11} duration={1} /> : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">
                    {language === "en-US"
                      ? "Projects delivered so far"
                      : "Şimdiye kadar teslim edilen projeler"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
