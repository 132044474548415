import React from "react";
import "../assets/scss/whatsapp.scss";

function WhatsAppFloat() {
  return (
    <div className="whatsapp-float">
      <a aria-label="Whatsapp" href="https://wa.me/+908503071350">
        <img src="/images/icon/whatsapp.gif" />
      </a>
    </div>
  );
}

export default WhatsAppFloat;
