import React, { useContext } from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ServiceProp from "../component/service/ServiceProp";
import ServiceData from "../data/service/ServiceMain.json";
import { slugify } from "../utils";
import { LanguageContext } from "../contextap/LanguageContext";
import ServisData from "../data/service/ServiceMainTR.json";

const ServiceOne = () => {
  const { language } = useContext(LanguageContext);

  let propData;
  switch (language) {
    case "en-US":
      propData = ServiceData;
      break;
    case "tr-TR":
      propData = ServisData;
      break;
    default:
      propData = ServiceData;
  }

  

  const designData = propData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "design"
  );
  const developmentData = propData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "development"
  );
  const marketingData = propData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "online-marketing"
  );

  return (
    <>
      <SEO title={language === "en-US" ? "Services" : "Hizmetler"} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title={
            language === "en-US"
              ? "Best solutions for your business"
              : "İşletmeniz için en iyi çözümler"
          }
          paragraph={
            language === "en-US"
              ? "Give your business a unique logo to stand out from the crowd. We’ll create a logo specifically for your company."
              : "İşletmenize kalabalıktan sıyrılmak için benzersiz bir logo verin. Sizin şirketiniz için özel bir logo oluşturacağız."
          }
          styleClass=""
          mainThumb="/images/banner/banner-thumb-4.png"
        />
        <div className="service-scroll-navigation-area">
          {/* Service Nav */}
          <nav
            id="onepagenav"
            className="service-scroll-nav navbar onepagefixed"
          >
            <div className="container">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <a className="nav-link" href="#section1">
                    {language === "en-US" ? "Design" : "Tasarım"}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#section2">
                    {language === "en-US" ? "Development" : "Geliştirme"}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#section3">
                    {language === "en-US"
                      ? "Online Marketing"
                      : "Online Pazarlama"}
                  </a>
                </li>
              </ul>
            </div>
          </nav>

          <div className="section section-padding" id="section1">
            <div className="container">
              <SectionTitle
                subtitle={language === "en-US" ? "Service" : "Hizmet"}
                title={language === "en-US" ? "Design" : "Tasarım"}
                description=""
                textAlignment="heading-left"
                textColor=""
              />
              <div className="row">
                <ServiceProp
                  colSize="col-lg-4 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={designData}
                />
              </div>
            </div>
          </div>

          <div className="section section-padding bg-color-light" id="section2">
            <div className="container">
              <SectionTitle
                subtitle={language === "en-US" ? "Service" : "Hizmet"}
                title={language === "en-US" ? "Development" : "Geliştirme"}
                description=""
                textAlignment="heading-left"
                textColor=""
              />
              <div className="row">
                <ServiceProp
                  colSize="col-lg-4 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={developmentData}
                />
              </div>
            </div>
          </div>

          <div className="section section-padding" id="section3">
            <div className="container">
              <SectionTitle
                subtitle={language === "en-US" ? "Service" : "Hizmet"}
                title={
                  language === "en-US" ? "Online Marketing" : "Online Pazarlama"
                }
                description=""
                textAlignment="heading-left"
                textColor=""
              />
              <div className="row">
                <ServiceProp
                  colSize="col-lg-4 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={marketingData}
                />
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ServiceOne;
