import React from "react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { LanguageContext } from "../../contextap/LanguageContext";

const Nav = () => {
  const { language, toggleLanguage } = React.useContext(LanguageContext);
  console.log("nav", language);
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="menu-item-has-children">
          {language === "tr-TR" ? (
            <Link to={process.env.PUBLIC_URL + "/"}>Anasayfa</Link>
          ) : (
            <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
          )}
        </li>
        <li className="menu-item-has-children">
          {language === "tr-TR" ? (
            <Link to={process.env.PUBLIC_URL + "/about-us"}>
              Hakkımızda
            </Link>
          ) : (
            <Link to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link>
          )}
        </li>
        <li className="menu-item-has-children">
          {language === "tr-TR" ? (
            <Link to={process.env.PUBLIC_URL + "/services"}>
              {" "}
              Servisler
            </Link>
          ) : (
            <Link to={process.env.PUBLIC_URL + "/services"}>Services</Link>
          )}
        </li>
        <li className="menu-item-has-children">
          {language === "tr-TR" ? (
            <Link to={process.env.PUBLIC_URL + "/portfolio"}>Projeler</Link>
          ) : (
            <Link to={process.env.PUBLIC_URL + "/portfolio"}>Portfolio</Link>
          )}
        </li>
        <li className="menu-item-has-children">
          {language === "tr-TR" ? (
            <Link to={process.env.PUBLIC_URL + "/contact"}>İletişim</Link>
          ) : (
            <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
          )}
        </li>
        <li className="menu-item-has-children">
          {language === "tr-TR" ? (
            <Link to="/" onClick={toggleLanguage}>EN</Link >
          ) : (
            <Link onClick={toggleLanguage}>TR</Link >
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
