import React, { createContext, useState } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    navigator.language || navigator.userLanguage || "en-US"
  );

  const toggleLanguage = () => {
    if (language === "en-US") {
      setLanguage("tr-TR");
    } else {
      setLanguage("en-US");
    }
  };

  const languageContextValue = {
    language,
    toggleLanguage,
  };

  return (
    <LanguageContext.Provider value={languageContextValue}>
      {children}
    </LanguageContext.Provider>
  );
};
