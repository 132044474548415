import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";
import { LanguageContext } from "../../contextap/LanguageContext";

const Result = ({ language }) => {
  return (
    <Alert variant="success" className="success-msg">
      {language === "en-US"
        ? "Your Message has been successfully sent."
        : "Mesajınız başarıyla gönderildi."}
    </Alert>
  );
};

const FormOne = () => {
  const form = useRef();
  const [result, showResult] = useState(false);
  const { language } = React.useContext(LanguageContext);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_yj5dgzp",
        "template_hfduayo",
        form.current,
        "WLENsTkBytC0yvItS"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
    showResult(true);

    setTimeout(() => {
      showResult(false);
    }, 5000);
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
      <div className="form-group">
        <label>{language === "en-US" ? "Name" : "İsim"}</label>
        <input
          type="text"
          className="form-control"
          name="contact-name"
          placeholder={
            language === "en-US" ? "John Smith" : "Adınız ve Soyadınız"
          }
          required
        />
      </div>
      <div className="form-group">
        <label>{language === "en-US" ? "Email" : "E-posta"}</label>
        <input
          type="email"
          className="form-control"
          name="contact-email"
          placeholder={
            language === "en-US" ? "example@mail.com" : "örnek@mail.com"
          }
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>{language === "en-US" ? "Phone" : "Telefon"}</label>
        <input
          type="tel"
          className="form-control"
          name="contact-phone"
          placeholder={language === "en-US" ? "+123456789" : "+123456789"}
          required
        />
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          {language === "en-US" ? "Get Free Quote" : "Ücretsiz Teklif Al"}
        </button>
      </div>
      <div className="form-group">
        {result ? <Result language={language} /> : null}
      </div>
    </form>
  );
};

export default FormOne;
