import React, { useContext } from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import { LanguageContext } from "../../contextap/LanguageContext";

const DataEn = [
  {
    id: 1,
    title: "Innovative Software Solutions",
    para: "We provide cutting-edge software solutions that push the boundaries of technology. Our team is dedicated to innovation and creating software that makes a difference.",
  },
  {
    id: 2,
    title: "Collaborative and Supportive Environment",
    para: "At FaemSoftware, we foster a collaborative and supportive work environment. We believe in the power of teamwork and helping each other succeed.",
  },
  {
    id: 3,
    title: "Continuous Learning and Growth",
    para: "We encourage our team members to be brave, curious, and open to experimentation. We learn from both our successes and failures, which leads to continuous personal and professional growth.",
  },
  {
    id: 4,
    title: "Pride in Our Work",
    para: "Every member of FaemSoftware takes pride in their work. We act in a way that reflects positively on ourselves and the company, ensuring that we deliver top-quality results.",
  },
  {
    id: 5,
    title: "Diverse, Transparent, and Responsible Culture",
    para: "Our company culture is built on diversity, transparency, and social responsibility. We believe in inclusivity and making a positive impact on society.",
  },
];

const DataTr = [
  {
    id: 1,
    title: "Yenilikçi Yazılım Çözümleri",
    para: "Teknolojinin sınırlarını zorlayan son teknoloji yazılım çözümleri sunuyoruz. Ekibimiz, yeniliğe ve fark yaratan yazılımlar oluşturmaya adanmıştır.",
  },
  {
    id: 2,
    title: "İşbirlikçi ve Destekleyici Ortam",
    para: "FaemSoftware'de işbirliği ve destekleyici bir çalışma ortamı oluşturuyoruz. Takım çalışmasının gücüne ve birbirimizin başarısına yardımcı olmaya inanıyoruz.",
  },
  {
    id: 3,
    title: "Sürekli Öğrenme ve Gelişme",
    para: "Takım üyelerimizi cesur, meraklı ve denemeye açık olmaları konusunda teşvik ediyoruz. Başarılarımızdan ve başarısızlıklarımızdan öğreniyoruz, bu da sürekli kişisel ve profesyonel gelişime yol açıyor.",
  },
  {
    id: 4,
    title: "İşimize Gurur Duyuyoruz",
    para: "FaemSoftware'un her üyesi işlerinden gurur duyar. Kendimize ve şirketimize olumlu yansıyan bir şekilde hareket ediyor, en yüksek kalitede sonuçlar sunmayı sağlıyoruz.",
  },
  {
    id: 5,
    title: "Çeşitli, Şeffaf ve Sorumlu Kültür",
    para: "Şirket kültürümüz, çeşitlilik, şeffaflık ve toplumsal sorumluluk üzerine kurulmuştur. Katılımcılığa inanıyor ve topluma olumlu bir etki yapmayı hedefliyoruz.",
  },
];

const AboutThree = () => {
  const { language } = useContext(LanguageContext);
  const data = language === "en-US" ? DataEn : DataTr;

  return (
    <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
      <div className="container">
        <SectionTitle
          subtitle={language === "en-US" ? "Our Values" : "Değerlerimiz"}
          title={
            language === "en-US"
              ? "Why should you work with us?"
              : "Neden bizimle çalışmalısınız?"
          }
          description={
            language === "en-US"
              ? "Nulla facilisi. Nullam in magna id dolor blandit rutrum eget vulputate augue sed eu leo eget risus imperdiet."
              : "Nulla facilisi. Nullam in magna id dolor blandit rutrum eget vulputate augue sed eu leo eget risus imperdiet."
          }
          textAlignment="heading-left heading-light-left mb--100"
          textColor=""
        />

        <div className="row">
          {data.map((item) => (
            <div className="col-lg-4" key={item.id}>
              <div className="about-quality">
                <h3 className="sl-number">{item.id}</h3>
                <h5 className="title">{item.title}</h5>
                <p>{item.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/circle-1.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"}
            alt="Circle"
          />
        </li>
      </ul>
    </div>
  );
};

export default AboutThree;
