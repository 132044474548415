import React, { useContext } from "react";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import FormTwo from "../component/contact/FormTwo";
import { LanguageContext } from "../contextap/LanguageContext";

const Contact = () => {
  const { language } = useContext(LanguageContext);

  return (
    <>
      <SEO title={language === "en-US" ? "Contact" : "İletişim"} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne
          title={language === "en-US" ? "Contact" : "İletişim"}
          page={language === "en-US" ? "Contact" : "İletişim"}
        />

        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <div className="contact-form-box shadow-box mb--30">
                  <h3 className="title">
                    {language === "en-US" ? "Contact Us" : "Bize Ulaşın"}
                  </h3>
                  <FormTwo />
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                  <h4 className="title">WhatsApp</h4>
                  <p>
                    {language === "en-US"
                      ? "Our customer care is open from Mon-Fri, 10:00 am to 6:00 pm"
                      : "Müşteri hizmetimiz Paz-Cum, 10:00-18:00 saatleri arasında açıktır"}
                  </p>
                  <h4 className="phone-number">
                    <a href="tel:08503071350">0 (850) 307 13 50</a>
                  </h4>
                </div>
                <div className="contact-info mb--30">
                  <h4 className="title">Email</h4>
                  <p>
                    {language === "en-US"
                      ? "Our support team will get back to you within 48 hours during standard business hours."
                      : "Destek ekibimiz, standart iş saatleri içinde 48 saat içinde size geri dönecektir."}
                  </p>
                  <h4 className="email">
                    <a href="mailto:info@faemsoftware.com.tr">
                      info@faemsoftware.com.tr
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>
        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default Contact;
