import React from "react";
import { Link } from "react-router-dom";
import ServiceData from "../../data/service/ServiceMain.json";
import ServiceDataTR from "../../data/service/ServiceMainTR.json";
import { slugify } from "../../utils";
import { LanguageContext } from "../../contextap/LanguageContext";

const AllData = ServiceData;
const TRData = ServiceDataTR;

const ServicePropOne = ({ colSize, serviceStyle, itemShow, marginTop }) => {
  const { language } = React.useContext(LanguageContext);

  const topMargin = (index) => {
    if (marginTop === "yes") {
      if (index === 0) {
        return "mt--200 mt_md--0";
      } else if (index === 1) {
        return "mt--100 mt_md--0";
      } else {
        return "mt--0";
      }
    } else {
      return "";
    }
  };

  return (
    <>
      {language === "en-US"
        ? AllData.slice(0, itemShow).map((data, index) => (
            <div className={`${colSize} ${topMargin(index)}`} key={index}>
              <div className={`services-grid ${serviceStyle}`}>
                <div className="thumbnail">
                  <img src={process.env.PUBLIC_URL + data.image} alt="icon" />
                </div>
                <div className="content">
                  <h5 className="title">
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        `/services/${slugify(data.title)}`
                      }
                    >
                      {data.title}
                    </Link>
                  </h5>
                  <p>{data.description}</p>
                  <Link
                    to={
                      process.env.PUBLIC_URL +
                      `/services/${slugify(data.title)}`
                    }
                    className="more-btn"
                  >
                    Find out more
                  </Link>
                </div>
              </div>
            </div>
          ))
        : TRData.slice(0, itemShow).map((data, index) => (
            <div className={`${colSize} ${topMargin(index)}`} key={index}>
              <div className={`services-grid ${serviceStyle}`}>
                <div className="thumbnail">
                  <img src={process.env.PUBLIC_URL + data.image} alt="icon" />
                </div>
                <div className="content">
                  <h5 className="title">
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        `/services/${slugify(data.title)}`
                      }
                    >
                      {data.title}
                    </Link>
                  </h5>
                  <p>{data.description}</p>
                  <Link
                    to={
                      process.env.PUBLIC_URL +
                      `/services/${slugify(data.title)}`
                    }
                    className="more-btn"
                  >
                    Daha Fazlası{" "}
                  </Link>
                </div>
              </div>
            </div>
          ))}
    </>
  );
};

export default ServicePropOne;
