import React from "react";
import { Link } from "react-router-dom";
import { slugify } from "../../utils";
import { LanguageContext } from "../../contextap/LanguageContext";

const ServiceProp = ({ colSize, serviceStyle, serviceData }) => {
  const { language } = React.useContext(LanguageContext);
  let message;

  switch (language) { 
    case "tr-TR":
      message = "Devamını Oku";
      break;
    case "en-US":
      message = "Find out more";
      break;
    default:
      message = "Find out more";
  }

  return (
    <>
      {serviceData.map((data, index) => (
        <div className={colSize} key={index}>
          <div className={`services-grid ${serviceStyle}`}>
            <div className="thumbnail">
              <img src={process.env.PUBLIC_URL + data.image} alt="icon" />
            </div>
            <div className="content">
              <h5 className="title">
                <Link
                  to={
                    process.env.PUBLIC_URL +
                    `/services/${slugify(data.title)}`
                  }
                >
                  {data.title}
                </Link>
              </h5>
              <p>{data.description}</p>
              <Link
                to={
                  process.env.PUBLIC_URL +
                  `/services/${slugify(data.title)}`
                }
                className="more-btn"
              >
                {" "}
                {message} 
              </Link>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ServiceProp;
