import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import { LanguageContext } from "../contextap/LanguageContext";

const PrivacyPolicy = () => {
  const { language } = React.useContext(LanguageContext);

  return (
    <>
      <SEO title="Privacy Policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Privacy Policy" page="Privacy Policy" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    {language === "en-US" ? (
                      <h5 className="title">
                        This Privacy policy was published on November 12th,
                        2023.
                      </h5>
                    ) : (
                      <h5 className="title">
                        Bu Gizlilik politikası 12 Kasım 2023 tarihinde
                        yayınlanmıştır.
                      </h5>
                    )}
                  </div>
                  {language === "en-US" ? (
                    <h4>Undertaking on the Protection of Personal Data</h4>
                  ) : (
                    <h4>Kişisel Verilerin Korunması Taahhüdü</h4>
                  )}
                  {language === "en-US" ? (
                    <p>
                      In line with the contracts we have concluded with Faem
                      Software Company ("Company") and for the purposes of the
                      legislation, including Articles 8 and 9 of the Personal
                      Data Protection Law No. 6698 ("Law"), we hereby accept,
                      declare and undertake that we have read, understood and
                      will act in accordance with the following matters in this
                      Undertaking on the Protection of Personal Data
                      ("Undertaking"). In this context
                    </p>
                  ) : (
                    <p>
                      Faem Software (“Şirket”) ile akdetmiş olduğumuz
                      sözleşmeler ve 6698 sayılı Kişisel Verilerin Korunması
                      Kanunu’nun (“Kanun”) 8. ve 9. maddeleri de dahil olmak
                      üzere mevzuat amaçları doğrultusunda, işbu Kişisel
                      Verilerin Korunmasına İlişkin Taahhütname’de
                      (“Taahhütname”) yer alan aşağıdaki hususları okuduğumuzu,
                      anladığımızı ve bu hususlara uygun davranacağımızı kabul,
                      beyan ve taahhüt ederiz. Bu bağlamda:
                    </p>
                  )}
                  {language === "en-US" ? (
                    <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                      1. Any kind of name-surname or title that can be obtained
                      within the scope of the commercial relationship between
                      the Company and us and that makes the identity of real
                      persons specific or identifiable, according to the
                      password number or one or more elements expressing the
                      psychological, physical, economic, cultural or social
                      self, which can be identified directly or indirectly
                      through its belonging, any information relating to natural
                      persons who can be identified on the basis of one or more
                      elements of all personal data, including but not limited
                      to identity/tax number/mersis number, age, gender,
                      occupation/commercial activity, health, genetic, ethnic,
                      ethnic, religious, familial or political information, is
                      personal data and is deemed to be "Confidential
                      Information", <br style={{ marginBottom: "10px" }} /> 2.
                      That we are obliged to show maximum effort and fulfill our
                      legal obligations regarding the protection of personal
                      data protected by the Turkish Constitution, Turkish
                      Criminal Code No. 5237, Turkish Civil Code No. 4721,
                      Turkish Civil Code No. 4721, Law No. 6698 on the
                      Protection of Personal Data and primary and / or secondary
                      legislation in force but not mentioned by name,{" "}
                      <br style={{ marginBottom: "10px" }} /> 3. That we will
                      process personal data only for the purposes specified by
                      the Company, and that the processing of personal data in
                      any way other than for the aforementioned purpose will
                      only be possible if and only if we fulfill the obligation
                      to inform the data subject within the scope of the Law for
                      such processing and obtain explicit consent where
                      necessary, <br style={{ marginBottom: "10px" }} /> 4. We
                      will keep personal data in our own custody in line with
                      the purpose specified by the Company and for the period
                      required for the fulfillment of this purpose and during
                      the continuation of the commercial relationship between
                      the Company and us and / or limited to the period
                      specified in the legislation, in case we use the services
                      of third party providers for the fulfillment of the
                      purpose in question, we may transfer personal data to
                      third parties, but in this case, the third parties in
                      question, that we will ensure compliance with the
                      provisions of this undertaking, that we are exclusively
                      responsible for the fulfillment of the conditions required
                      by the Law for the transfer of personal data to third
                      parties abroad and for compliance with all regulations to
                      be issued by the Personal Data Protection Authority, if it
                      is necessary to transfer personal data abroad by us or our
                      providers under this article,{" "}
                      <br style={{ marginBottom: "10px" }} /> 5. That we are
                      obliged to take all necessary legal, technical and
                      environmental measures to prevent unauthorized access to
                      personal data by both our own personnel and third parties
                      and to prevent the use of personal data for purposes other
                      than the transfer of personal data to us, and that the
                      measures to be taken within this scope will in any case
                      not be less than the measures taken for the security of
                      personal data stored by the applicable legislation (if
                      any) or by a prudent merchant operating in similar fields,
                      <br style={{ marginBottom: "10px" }} /> 6. In cases where
                      personal data is transferred to a third party as permitted
                      by the Company, we are responsible for ensuring that the
                      data transfer is secure,
                      <br style={{ marginBottom: "10px" }} /> 7. In the event of
                      any unauthorized access to personal data in any way or in
                      the event that personal data becomes accessible by third
                      parties in any way in violation of this Undertaking and/or
                      mutual agreements, we will notify the Company immediately
                      (within the same day) and we will provide all kinds of
                      information, documents and support requested by the
                      Company without delay in order to prevent any damage or to
                      minimize the damage that may occur,
                      <br style={{ marginBottom: "10px" }} /> 8. That we are
                      obliged to inform our personnel and, if any, the personnel
                      of our subcontractors, consultants and / or proxies in
                      writing on the matters stipulated within the scope of this
                      Undertaking regarding the processing of personal data, and
                      that we are jointly and severally liable with the said
                      persons for any damages that may arise if the said
                      personnel act contrary to the regulations in the
                      contractual agreements, this Undertaking or the
                      legislation,
                      <br style={{ marginBottom: "10px" }} /> 9. After the
                      expiration of the term and / or in cases where there is no
                      possibility of justified use / disappearance, we will
                      return all kinds of media and media where personal data
                      are stored and the records in our custody against a report
                      bearing the signatures of both parties, and in the
                      presence of an express written request of the Company, we
                      will delete or destroy it in accordance with the request
                      in question,
                      <br style={{ marginBottom: "10px" }} />
                      10. In the event that the personal data owner makes a
                      request from us in any way regarding his/her rights under
                      the relevant legislation, we will immediately (in any case
                      the next business day) notify the Company in writing
                      regarding the request in question,
                      <br style={{ marginBottom: "10px" }} />
                      11. That we are subject to the audit of the Company and
                      the Personal Data Protection Authority in terms of
                      personal data processing activities within the scope of
                      the Letter of Undertaking, that the Company may exercise
                      the said audit right personally or by a third party or
                      request us to perform the said audit,
                      <br style={{ marginBottom: "10px" }} /> 12. In the event
                      that we act contrary to this Undertaking and therefore
                      cause any damage to the Company, we are exclusively
                      responsible for any damages that may occur both before
                      third parties and the Company, and that we are obliged to
                      compensate the said damage immediately on the first
                      request date of the Company, We are directly responsible
                      for the penalties and damage claims that may be directed
                      to the Company by all kinds of administrative authorities
                      in accordance with this Letter of Undertaking, and in the
                      event that the Company is obliged to make payments under
                      any name and name to both third parties and all kinds of
                      administrative authorities in accordance with this Letter
                      of Undertaking, the Company has the right to claim
                      recourse to us for such amounts, and in this context, we
                      will fulfill the requirement of the request within 3
                      (three) days following the submission of the recourse
                      request by the Company to us, accept, declare and
                      undertake.
                    </p>
                  ) : (
                    <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                      1. Şirket ile aramızdaki ticari ilişki kapsamında temin
                      edilebilecek ve gerçek kişilerin kimliğini belirli veya
                      belirlenebilir kılan, şifre numarasına göre ya da
                      psikolojik, fiziksel, ekonomik, kültürel veya sosyal
                      benliğini ifade eden bir veya birden fazla unsura,
                      aidiyeti aracılığıyla doğrudan veya dolaylı olarak teşhis
                      edilebilmesine yarayan her türlü ad-soyad veya unvan,
                      kimlik/vergi numarası/mersis numarası, yaş, cinsiyet,
                      meslek/ticari faaliyet, sağlık, genetik, etnik, dini,
                      ailevi veya siyasi bilgilerinin ancak bunlarla sınırlı
                      olmaksızın tüm kişisel verilerin bir ya da birden fazla
                      unsuruna dayanarak tanımlanabilen gerçek kişilere ilişkin
                      her türlü bilginin kişisel veri olduğunu ve evleviyetle
                      “Gizli Bilgi” olarak kabul edildiğini,{" "}
                      <br style={{ marginBottom: "10px" }} />
                      2. Başta T.C. Anayasası olmak üzere, 5237 sayılı Türk Ceza
                      Kanunu, 4721 sayılı Türk Medeni Kanunu, 6698 sayılı
                      Kişisel Verilerin Korunması Kanunu ve ismen belirtilmeyen
                      ancak yürürlükte bulunan birincil ve/veya ikincil mevzuat
                      ile koruma altına alınmış kişisel verilerin korunması
                      hususunda azami çabayı göstermek ve kanuni
                      mükellefiyetlerimizi yerine getirmekle yükümlü olduğumuzu,{" "}
                      <br style={{ marginBottom: "10px" }} /> 3. Kişisel
                      verileri, yalnızca Şirket tarafından belirtilen amaçlar
                      doğrultusunda işleyeceğimizi, Kişisel verilerin, anılan
                      amaç dışında herhangi bir şekilde işlenmesinin ancak ve
                      ancak tarafımızca söz konusu işlemeye yönelik olarak veri
                      sahibine karşı Kanun kapsamında aydınlatma yükümlülüğünü
                      yerine getirmemiz ve gerekli hallerde açık rıza almamız
                      halinde mümkün olacağını,{" "}
                      <br style={{ marginBottom: "10px" }} />
                      4. Kişisel verileri Şirket tarafından belirtilen amaç
                      doğrultusunda ve bu amacın ifası için gerekli olan süre ve
                      Şirket ile aramızdaki ticari ilişkinin devamı süresince
                      ve/veya mevzuatta belirtilen süre ile sınırlı olarak kendi
                      nezdimizde saklayacağımızı, söz konusu amacın ifası için
                      üçüncü kişi konumundaki sağlayıcıların hizmetlerini
                      kullanmamız halinde kişisel verileri üçüncü kişilere
                      aktarabileceğimizi ancak bu halde söz konusu üçüncü
                      kişilerin, işbu taahhütname hükümlerine riayet etmesini
                      sağlayacağımızı, bu madde tahtında tarafımızca ya da
                      sağlayıcılarımız tarafından yurt dışına kişisel veri
                      aktarımının gerekli olması halinde kişisel verilerin
                      yurtdışındaki üçüncü kişilere aktarımı için Kanun’un
                      aradığı şartların yerine getirilmesinden ve Kişisel
                      Verileri Koruma Kurumu tarafından çıkarılacak tüm
                      düzenlemelere riayet edilmesinden münhasıran sorumlu
                      olduğumuzu,
                      <br style={{ marginBottom: "10px" }} /> 5. Kişisel
                      verilere gerek kendi personelimiz gerekse üçüncü
                      taraflarca yetkisiz erişilmesini ve kişisel verilerin
                      tarafımıza aktarımı amacı dışında kullanılmasını
                      engelleyecek şekilde hukuki, teknik ve çevresel olarak tüm
                      gerekli önlemleri almakla yükümlü olduğumuzu, bu kapsamda
                      alınacak önlemlerin her halükarda (varsa) yürürlükteki
                      mevzuat veya benzer alanlarda faaliyet gösteren basiretli
                      bir tacir tarafından kendi nezdinde saklanan kişisel
                      verilerin güvenliği için alınan önlemlerden daha az
                      olmayacağını, <br style={{ marginBottom: "10px" }} />
                      6. Şirket tarafından izin verilen şekilde kişisel
                      verilerin üçüncü bir tarafa aktarımının gerçekleştirildiği
                      hallerde de veri aktarımının güvenli bir şekilde
                      sağlanmasından sorumlu olduğumuzu,
                      <br style={{ marginBottom: "10px" }} /> 7. Kişisel
                      verilere herhangi bir şekilde yetkisiz erişim
                      gerçekleşmesi veya kişisel verilerin herhangi bir şekilde
                      işbu Taahhütname ve/veya münakit anlaşmalara aykırı
                      şekilde üçüncü taraflarca erişilebilir hale gelmesi
                      durumunda bu durumu derhal (aynı gün içerisinde) Şirket’e
                      bildireceğimizi ve bir zararın doğmaması ya da
                      oluşabilecek zararın asgariye indirilebilmesi için Şirket
                      tarafından talep edilen her türlü bilgi, belge ve desteği
                      gecikmeksizin sağlayacağımızı,
                      <br style={{ marginBottom: "10px" }} /> 8. Kişisel
                      verilerin işlenmesine ilişkin olarak işbu Taahhütname
                      kapsamında öngörülen hususlarda personelimizi ve varsa alt
                      yüklenicilerimizin personellerini, danışmanlarımızı
                      ve/veya vekillerimizi yazılı bir şekilde bilgilendirmekle
                      yükümlü olduğumuzu, bahsi geçen personellerin, münakit
                      anlaşmalar, işbu Taahhütname veya mevzuatta yer alan
                      düzenlemelere aykırı davranması halinde oluşacak
                      zararlardan söz konusu kişilerle ile müştereken ve
                      müteselsilen sorumlu olduğumuzu,
                      <br style={{ marginBottom: "10px" }} /> 9. Süresi sona
                      erdikten sonra ve/veya haklı kullanım imkânı
                      olmadığı/ortadan kalktığı hallerde kişisel verilerin
                      kayıtlı bulunduğu her türlü medya ve ortamını ve
                      nezdimizdeki kayıtları her iki tarafın imzalarını havi
                      tutanak mukabili iade edeceğimizi ve Şirket’in açık yazılı
                      talebinin varlığı halinde söz konusu talebe uygun olarak
                      sileceğimizi veya yok edeceğimizi,{" "}
                      <br style={{ marginBottom: "10px" }} /> 10. Kişisel veri
                      sahibinin herhangi bir şekilde tarafımızdan ilgili yasal
                      mevzuat kapsamındaki haklarıyla ilgili bir talepte
                      bulunması halinde, söz konusu talebe ilişkin olarak derhal
                      (her halükârda ertesi iş günü) Şirket’e yazılı bildirimde
                      bulunacağımızı,
                      <br style={{ marginBottom: "10px" }} /> 11. Taahhütname
                      kapsamındaki kişisel veri işleme faaliyetleri bakımından
                      Şirket ve Kişisel Verileri Koruma Kurumu denetimine tabi
                      olduğumuzu, Şirket’in söz konusu denetim hakkını bizzat
                      veya üçüncü bir kişi eliyle kullanabileceğini veya
                      tarafımızdan söz konusu denetimi gerçekleştirmesini talep
                      edebileceğini,
                      <br style={{ marginBottom: "10px" }} /> 12. İşbu
                      Taahhütnameye aykırı davranmamız ve bu nedenle Şirket
                      nezdinde herhangi bir zarara neden olmamız halinde gerek
                      üçüncü şahıslar gerekse Şirket nezdinde meydana
                      gelebilecek olan zararlardan münhasıran sorumlu
                      olduğumuzu, söz konusu zararı Şirket’in ilk talep
                      tarihinde derhal tazmin etmekle mükellef olduğumuzu, işbu
                      Taahhütname doğrultusunda Şirket’e her türlü idari
                      makamlar tarafından yöneltilebilecek ceza ve zarar
                      taleplerinden de doğrudan sorumlu olduğumuzu ve Şirket’in
                      işbu taahhütname gereği gerek üçüncü şahıslar gerekse her
                      türlü idari makama herhangi bir nam ve ad altında ödeme
                      yapmak zorunda kalması halinde Şirket’in, söz konusu
                      tutarlara ilişkin tarafımıza rücu talep hakkının
                      bulunduğunu, bu kapsamda Şirket tarafından rücu talebinin
                      tarafımıza iletilmesini müteakip 3 (üç) gün içerisinde
                      talep gereğini yerine getireceğimizi, kabul, beyan ve
                      taahhüt ederiz.{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default PrivacyPolicy;
