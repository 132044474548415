import React, { useState, useEffect, useContext } from "react";
import ProjectPropOne from "./itemProp/ProjectPropOne";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ProjectData from "../../data/project/ProjectData.json";
import ProjectDataTR from "../../data/project/projectDataTR.json";
import { LanguageContext } from "../../contextap/LanguageContext";

const AllData = ProjectData;
const AllDataTR = ProjectDataTR;

const ProjectOne = ({ parentClass, colSize, itemShow, columnGap }) => {
  const [getAllItems] = useState(AllData);
  const [visiableProject] = useState(itemShow ? itemShow : 6);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [getAllItemsTR] = useState(AllDataTR);
  const { language } = useContext(LanguageContext);
  useEffect(() => {
    const updateVisibleItems = () => {
      setActiveFilter(language === "en-US" ? "All Works" : "Tüm Çalışmalar");
      if (language === "en-US") {
        setVisibleItems(
          getAllItems.filter((item) => item.id <= visiableProject)
        );
      } else {
        setVisibleItems(
          getAllItemsTR.filter((item) => item.id <= visiableProject)
        );
      }
    };

    updateVisibleItems(); // Call the function immediately after defining it
  }, [language, getAllItems, getAllItemsTR, visiableProject]);

  const filters =
    language === "en-US"
      ? [
          { id: 1, label: "All Works" },
          { id: 3, label: "Mobile" },
        ]
      : [
          { id: 1, label: "Tüm Çalışmalar" },
          { id: 3, label: "Mobil" },
        ];

  const handleChange = (e) => {
    e.preventDefault();
    let target = e.target.textContent;

    setActiveFilter(target);

    let tempData = [];
    if (target === (language === "en-US" ? "All Works" : "Tüm Çalışmalar")) {
      tempData = getAllItems.filter((data) => data.id <= visiableProject);
    } else {
      for (let i = 0; i < getAllItems.length; i++) {
        const element = getAllItems[i];
        let categories = element["category"];

        if (categories.includes(target)) {
          tempData.push(element);
        }
      }
    }
    setVisibleItems(tempData);
  };

  return (
    <>
      <div
        className={`section section-padding-2 ${
          parentClass ? parentClass : ""
        }`}
      >
        <div className="container">
          <SectionTitle
            subtitle={language === "en-US" ? "Our Project" : "Projelerimiz"}
            title={
              language === "en-US"
                ? "Some of our finest work."
                : "En iyi işlerimizden bazıları."
            }
            textAlignment="heading-left mb--40"
            textColor=""
          />
          <div className="isotope-button isotope-project-btn">
            {filters.map((filter) => (
              <button
                onClick={handleChange}
                className={filter.label === activeFilter ? "is-checked" : " "}
                key={filter.id}
              >
                {filter.label}
              </button>
            ))}
          </div>
          <div className={`row ${columnGap ? columnGap : "row-35"}`}>
            {visibleItems.map((data) => (
              <div className={colSize ? colSize : "col-md-6"} key={data.id}>
                <ProjectPropOne projectStyle="" portfolio={data} />
              </div>
            ))}
          </div>
        </div>
        <ul className="shape-group-7 list-unstyled">
          <li className="shape shape-1">
            <img
              src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
              alt="circle"
            />
          </li>
          <li className="shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
              alt="Line"
            />
          </li>
          <li className="shape shape-3">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
              alt="Line"
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProjectOne;
