import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import CounterUp from "../../component/counterup/CounterUp";
import { LanguageContext } from "../../contextap/LanguageContext";

const CounterUpOne = () => {
  const { language } = React.useContext(LanguageContext);

  return (
    <div className="section section-padding bg-color-dark">
      <div className="container">
        {language === "en-US" ? (
          <SectionTitle
            subtitle="Featured Case Study"
            title="Startup movement"
            description="The number of projects we have successfully completed is more than just a milestone"
            textAlignment="heading-light"
            textColor=""
          />
        ) : (
          <SectionTitle
            subtitle="Öne Çıkan  Çalışmalar"
            title="Startup hareketi"
            description="Başarıyla tamamladığımız projelerin sayısı sadece bir kilometre taşı değil"
            textAlignment="heading-light"
            textColor=""
          />
        )}
        <div className="row">
          <CounterUp colSize="col-lg-3 col-6" layoutStyle="" evenTopMargin="" />
        </div>
      </div>
    </div>
  );
};

export default CounterUpOne;
