import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";

const TermsOfUse = () => {
  return (
    <>
      <SEO title="Terms Of Use" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Terms Of Use" page="Terms Of Use" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <h1>Terms of Use</h1>
                  <p>
                    Welcome to Faem Software! Please carefully read the
                    following terms of use.
                  </p>

                  <h2>Article 1: Acceptance of Terms and Conditions</h2>
                  <p>
                    By using our services, you agree to be bound by these terms
                    and conditions. Please read them carefully.
                  </p>

                  <h2>Article 2: Privacy and Data Policy</h2>
                  <p>
                    We are committed to protecting your privacy. For details on
                    how we handle your data, please refer to our{" "}
                    <a>Data Policy</a>.
                  </p>

                  <h2>Article 3: Contact Us</h2>
                  <p>
                    If you have any questions or need to get in touch with us,
                    please use the following email address:{" "}
                    <a href="mailto:info@faemsoftware.com.tr">
                      info@faemsoftware.com
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default TermsOfUse;
