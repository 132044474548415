import React, { useContext } from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectFive from "../component/project/ProjectFive";
import { LanguageContext } from "../contextap/LanguageContext";

const ProjectGridFive = () => {
  const { language } = useContext(LanguageContext);

  return (
    <>
      <SEO
        title={
          language === "en-US"
            ? "Project Full Width Four Column"
            : "Proje Tam Genişlik Dört Sütun"
        }
      />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title={language === "en-US" ? "Our Projects" : "Projelerimiz"}
          paragraph={
            language === "en-US"
              ? "Faem Software is a software company that develops software solutions for the industry."
              : "Faem Software, yenilenen teknoloji için yazılım çözümleri geliştiren bir yazılım şirketidir."
          }
          styleClass=""
          mainThumb="/images/banner/banner-thumb-1.png"
        />
        <ProjectFive
          colSize="row-cols-sm-2 row-cols-lg-3 row-cols-xl-4"
          parentClass="project-column-4"
          perPageShow="8"
        />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ProjectGridFive;
