import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import DigitalAgency from "./pages/DigitalAgency";
import ServiceOne from "./pages/ServiceOne";
import ServiceDetails from "./pages/ServiceDetails";
import ProjectGridTwo from "./pages/ProjectGridTwo";
import ProjectGridFive from "./pages/ProjectGridFive";
import ProjectDetails from "./pages/ProjectDetails";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import ErrorPage from "./pages/404";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import "./assets/scss/app.scss";
import { LanguageContext } from "./contextap/LanguageContext";
import ReactWhatsapp from "react-whatsapp";
import WhatsAppFloat from "./pages/WhatsAppFloat";

const App = () => {
  const { language } = React.useContext(LanguageContext);
  console.log(language);

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <>
            <Route path="/" element={<DigitalAgency />} />
            <Route path="/services" element={<ServiceOne />} />
            <Route path="/services/:slug" element={<ServiceDetails />} />
            <Route path="/project-grid-two" element={<ProjectGridTwo />} />
            <Route path="/portfolio" element={<ProjectGridFive />} />
            <Route path="/project/:slug" element={<ProjectDetails />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-use" element={<TermsOfUse />} />
            <Route path="/*" element={<ErrorPage />} />
          </>
        </Routes>
      </ScrollToTop>
      <WhatsAppFloat />

    </Router>
  );
};

export default App;
