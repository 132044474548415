import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaPinterestP,
  FaLinkedin,
  FaInstagram,
  FaVimeoV,
  FaDribbble,
  FaBehance,
  FaEnvelopeOpen,
  FaTiktok,
} from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from "../../utils";
import { LanguageContext } from "../../contextap/LanguageContext";

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  const { language } = useContext(LanguageContext);

  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-top">
          <div className="footer-social-link">
            <ul className="list-unstyled">
              <li>
                <Link to="https://facebook.com/">
                  <FaFacebookF />
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/">
                  <FaTwitter />
                </Link>
              </li>
              <li>
                <Link to="https://www.linkedin.com/">
                  <FaLinkedin />
                </Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/">
                  <FaInstagram />
                </Link>
              </li>
              <li>
                <Link to="https://www.behance.net/">
                  <FaBehance />
                </Link>
              </li>
              <li>
                <Link to="https://www.tiktok.com/en/">
                  <FaTiktok />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <h2 className="title">
                    {language === "en-US" ? "Get in touch" : "İletişime Geç"}
                  </h2>
                  <p>
                    {language === "en-US"
                      ? "Stay tuned for our innovations and announcements"
                      : "Yeniliklerimiz ve duyurularımız için takipte kalın"}
                  </p>
                  <form>
                    <div className="input-group">
                      <span className="mail-icon">
                        <FaEnvelopeOpen />{" "}
                      </span>
                      <input
                        type="email"
                        className="form-control"
                        placeholder={
                          language === "en-US"
                            ? "Email address"
                            : "E-posta adresi"
                        }
                      />
                      <button className="subscribe-btn" type="submit">
                        {language === "en-US" ? "Subscribe" : "Abone Ol"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="row">
                <div className="col-sm-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">
                      {language === "en-US" ? "Services" : "Hizmetler"}
                    </h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        {getServiceData.slice(0, 3).map((data, index) => (
                          <li key={index}>
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                `/services/${slugify(data.title)}`
                              }
                            >
                              {data.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">
                      {language === "en-US" ? "Support" : "Destek"}
                    </h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/contact"}>
                            {language === "en-US" ? "Contact" : "İletişim"}
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                            {language === "en-US"
                              ? "Privacy Policy"
                              : "Gizlilik Politikası"}
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                            {language === "en-US"
                              ? "Terms of Use"
                              : "Kullanım Şartları"}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}.{" "}
                  {language === "en-US"
                    ? "All rights reserved by"
                    : "Tüm hakları saklıdır, tarafından sağlanır"}{" "}
                  <a href="https://faemsoftware.com/">Faem Software</a>.
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                      {language === "en-US"
                        ? "Privacy Policy"
                        : "Gizlilik Politikası"}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                      {language === "en-US"
                        ? "Terms of Use"
                        : "Kullanım Şartları"}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
