import React from "react";
import FormOne from "../contact/FormOne";
import { useContext } from "react";
import { LanguageContext } from "../../contextap/LanguageContext";

const AboutOne = () => {
  const { language } = useContext(LanguageContext);
  return (
    <section className="section section-padding-equal bg-color-light">
      <div className="container">
        <div className="row align-items-center">
          {language === "en-US" ? (
            <div className="col-lg-6">
              <div className="about-us">
                <div className="section-heading heading-left mb-0">
                  <span className="subtitle">About Us</span>
                  <h2 className="title mb--40">
                    We do design, code &amp; develop.
                  </h2>
                  <p>
                    Faem Software is a software company founded by a passionate
                    team with a focus on technology and innovation.{" "}
                  </p>
                  <p>
                    As a young and dynamic start-up, our goal is to meet our
                    customers' technological needs and help make their
                    businesses more efficient.{" "}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-lg-6">
              <div className="about-us">
                <div className="section-heading heading-left mb-0">
                  <span className="subtitle">Hakkımızda</span>
                  <h2 className="title mb--40">
                    Tasarım, kodlama ve &amp; geliştirmek.{" "}
                  </h2>
                  <p>
                    Faem Software tutkulu bir kişi tarafından kurulmuş bir
                    yazılım şirketidir. teknoloji ve inovasyona odaklanan bir
                    ekip.{" "}
                  </p>
                  <p>
                    Genç ve dinamik bir start-up olarak hedefimiz; müşterilerin
                    teknolojik ihtiyaçlarını karşılamaya yardımcı olur
                    İşletmeler daha verimli.{" "}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="contact-form-box">
              {language === "en-US" ? (
                <h3 className="title">Contact now for a free quote</h3>
              ) : (
                <h3 className="title">
                  Ücretsiz teklif için şimdi iletişime geçin
                </h3>
              )}
              <FormOne />
            </div>
          </div>
        </div>
      </div>
      <ul className="shape-group-6 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
            alt="line"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
            alt="line"
          />
        </li>
      </ul>
    </section>
  );
};

export default AboutOne;
