import React, { useContext } from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessOne from '../component/process/ProcessOne';
import AboutThree from '../component/about/AboutThree';
import AboutFour from '../component/about/AboutFour';
import AboutFive from '../component/about/AboutFive';
import { LanguageContext } from "../contextap/LanguageContext";

const AboutUs = () => {
    const { language } = useContext(LanguageContext);

    return (
        <>
        <SEO title={language === "en-US" ? "About Us" : "Hakkımızda"} />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title={language === "en-US" ? "One of the fastest growing agency" : "En hızlı büyüyen ajanslardan biri"}
                paragraph ={language === "en-US" ? "We design and develop web and mobile applications for our clients worldwide." : "Dünya genelindeki müşterilerimiz için web ve mobil uygulamalar tasarlıyoruz ve geliştiriyoruz."}
                styleClass="thumbnail-4"
                mainThumb="/images/banner/banner-thumb-3.png"
                />
                <AboutFour />
                <AboutThree />
                <ProcessOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;
