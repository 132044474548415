import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import AboutOne from "../component/about/AboutOne";
import BannerOne from "../component/banner/BannerOne";
import CounterUpOne from "../component/counterup/CounterUpOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectOne from "../component/project/ProjectOne";
import ServicePropOne from "../component/service/ServicePropOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import { LanguageContext } from "../contextap/LanguageContext";

const DigitalAgency = () => {
  const { language } = React.useContext(LanguageContext);

  return (
    <>
      <SEO title="Faem Software" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BannerOne />
        {language === "en-US" ? (
          <div className="section section-padding-2 bg-color-dark">
            <div className="container">
              <SectionTitle
                subtitle="What We Can Do For You"
                title="Services we can help you with"
                description="Meeting Needs, Achieving Goals: Explore Our Services and Succeed Sooner"
                textAlignment="heading-light-left"
                textColor=""
              />
              <div className="row">
                <ServicePropOne
                  colSize="col-xl-4 col-md-6"
                  serviceStyle=""
                  itemShow="3"
                />
              </div>
            </div>
            <ul className="list-unstyled shape-group-10">
              <li className="shape shape-1">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/line-9.png"}
                  alt="Circle"
                />
              </li>
              <li className="shape shape-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"}
                  alt="Circle"
                />
              </li>
              <li className="shape shape-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"}
                  alt="Circle"
                />
              </li>
            </ul>
          </div>
        ) : (
          <div className="section section-padding-2 bg-color-dark">
            <div className="container">
              <SectionTitle
                subtitle="Sizin için ne yapabiliriz"
                title="Sizin için hizmetlerimiz"
                description="İhtiyaçları Karşılamak, Hedeflere Ulaşmak: Hizmetlerimizi Keşfedin ve Daha Kısa Sürede Başarılı Olun"
                textAlignment="heading-light-left"
                textColor=""
              />
              <div className="row">
                <ServicePropOne
                  colSize="col-xl-4 col-md-6"
                  serviceStyle=""
                  itemShow="3"
                />
              </div>
            </div>
            <ul className="list-unstyled shape-group-10">
              <li className="shape shape-1">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/line-9.png"}
                  alt="Circle"
                />
              </li>
              <li className="shape shape-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"}
                  alt="Circle"
                />
              </li>
              <li className="shape shape-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"}
                  alt="Circle"
                />
              </li>
            </ul>
          </div>
        )}
        <AboutOne />
        <ProjectOne />
        <CounterUpOne />
        {/* <TestimonialOne /> */}
        {/* <div className="section bg-color-light section-padding">
          <div className="container">
            <SectionTitle
              subtitle="Pricing Plan"
              title="We’ve built solutions for..."
              description="Flexible pricing options for freelancers
                        and design teams."
              textAlignment=""
              textColor=""
            />
            <PricingOne />
          </div>
          <ul className="list-unstyled shape-group-3">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-1.png"}
                alt="shape"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"}
                alt="shape"
              />
            </li>
          </ul>
        </div> */}
        {/* <BrandOne /> */}
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default DigitalAgency;
