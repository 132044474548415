import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaBehance,
  FaPhone,
} from "react-icons/fa";
import { LanguageContext } from "../../contextap/LanguageContext";

const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
  const { language } = useContext(LanguageContext);
  return (
    <Offcanvas
      show={offcanvasShow}
      onHide={offcanvasHide}
      placement="end"
      className="header-offcanvasmenu"
    >
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        <form action="#" className="side-nav-search-form"></form>
        <div className="row ">
          <div className="col-lg-5 col-xl-6">
            {language === "en-US" ? (
              <ul className="main-navigation list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/about-us"}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/services"}>
                    Services
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/portfolio"}>
                    Portfolio
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
                </li>
              </ul>
            ) : (
              <ul className="main-navigation list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/"}>Anasayfa</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/about-us"}>
                    Hakkımızda
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/services"}>
                    Hizmetlerimiz
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/portfolio"}>
                    Portfolyo
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/contact"}>İletişim</Link>
                </li>
              </ul>
            )}
          </div>
          <div className="col-lg-7 col-xl-6">
            <div className="contact-info-wrap">
              <div className="contact-inner">
                {language === "en-US" ? (
                  <address className="address">
                    <span className="title">Contact Information</span>
                    <p>
                      Turkey Istanbul <br /> A+Live Plaza
                    </p>
                  </address>
                ) : (
                  <address className="address">
                    <span className="title">İletişim Bilgileri</span>
                    <p>
                      Türkiye İstanbul <br /> A+Live Plaza
                    </p>
                  </address>
                )}
                {language === "en-US" ? (
                  <address className="address">
                    <span className="title">
                      We're Available 24/7. Call Now.
                    </span>
                    <a href="tel:5395964409" className="tel">
                      <FaPhone /> (850) 307 13 50
                    </a>
                  </address>
                ) : (
                  <address className="address">
                    <span className="title">
                      7/24 Ulaşılabilirsiniz. Hemen Arayın.
                    </span>
                    <a href="tel:5395964409" className="tel">
                      <FaPhone /> (850) 307 13 50
                    </a>
                  </address>
                )}
              </div>
              <div className="contact-inner">
                {language === "en-US" ? (
                  <h5 className="title">Find us here</h5>
                ) : (
                  <h5 className="title">Bizi Buradan Bulabilirsiniz</h5>
                )}{" "}
                <div className="contact-social-share">
                  <ul className="social-share list-unstyled">
                    <li>
                      <a href="https://facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>

                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.behance.net/">
                        <FaBehance />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMenu;
